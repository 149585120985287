<template>
  <div class="Little Amy Pocket">
    Cumming Soon
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  created () {
    window.location.replace('https://linktr.ee/AmyPocket')
  }

}
</script>
